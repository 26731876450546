"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Robot.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgRobot = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M22 14H10c-1.105 0-2 .739-2 1.65v7.7c0 .911.895 1.65 2 1.65h12c1.105 0 2-.739 2-1.65v-7.7c0-.911-.895-1.65-2-1.65ZM19.857 5h-7.714C10.959 5 10 6.343 10 8s.96 3 2.143 3h7.714C21.041 11 22 9.657 22 8s-.96-3-2.143-3ZM18.5 25v5M18.5 30h2M11.5 30h2M24.25 16.519s1.402-.175 2.546.544c.788.496 1.454 1.609 1.454 2.718V22.5M7.75 16.481s-1.402.175-2.545-.544c-.789-.496-1.455-1.609-1.455-2.718V10.5M17 18h4M27.25 24s.125-1 1-1 1 1 1 1M4.75 9s-.125 1-1 1-1-1-1-1M17 20.5h4M13.5 25v5M16 5V2M16 14v-3"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          fill: "currentColor",
          d: "M13.75 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM18.25 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        }
      )
    ]
  }
));
var Robot_default = SvgRobot;

export {
  Robot_default
};
