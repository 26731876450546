'use client';

import React, { createContext } from 'react';

interface EnvContextValue {
  readonly baseUrl: string;
  readonly hermesUrl: string;
  readonly achelousUrl: string;
}

export const EnvContext = createContext<EnvContextValue>({
  baseUrl: '',
  hermesUrl: '',
  achelousUrl: '',
});

export function EnvContextProvider({ value, children }: { value: EnvContextValue; children: React.ReactNode }) {
  return <EnvContext.Provider value={value}>{children}</EnvContext.Provider>;
}
