import { useCallback, useMemo } from 'react';
import axios, { Axios } from 'axios';
import { useEnv } from './useEnv';

interface UseApi {
  readonly apiClient: Axios;
  readonly apiFetch: (url: string, method?: string) => Promise<Response>;
}

export function useApi(): UseApi {
  const { baseUrl } = useEnv();

  const apiClient = useMemo(
    () =>
      axios.create({
        baseURL: baseUrl,
        withCredentials: true,
        xsrfCookieName: 'csrf-token',
        xsrfHeaderName: 'X-Csrf-Token',
        headers: {
          'Cache-Control': 'no-cache',
        },
      }),
    [baseUrl]
  );

  const apiFetch = useCallback(
    (url: string, method = 'GET'): Promise<Response> => {
      return fetch(`${baseUrl}${url}`, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        },
      });
    },
    [baseUrl]
  );

  return { apiClient, apiFetch };
}
