"use client";

// src/withPhosphorWrapper.tsx
import { forwardRef } from "react";
import {
  IconBase
} from "@phosphor-icons/react";
import { jsx } from "react/jsx-runtime";
var withPhosphorWrapper = (Component) => {
  const { children, fill, viewBox } = Component({}).props;
  const weights = /* @__PURE__ */ new Map([
    ["thin", children],
    ["light", children],
    ["regular", children],
    ["bold", children],
    ["fill", children],
    ["duotone", children]
  ]);
  const IconComponent = forwardRef((props, ref) => /* @__PURE__ */ jsx(
    IconBase,
    {
      ref,
      viewBox,
      fill,
      weights,
      ...props
    }
  ));
  IconComponent.displayName = Component.name;
  return IconComponent;
};

export {
  withPhosphorWrapper
};
